var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tech-service-basic-edit" },
    [
      _c(
        "el-form",
        {
          key: "mainForm",
          ref: "mainForm",
          staticClass: "zwx-form edit-form",
          attrs: { model: _vm.mainForm, rules: _vm.rules },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "110px",
                label: "单位名称：",
                prop: "organName"
              }
            },
            [
              _c("unit-select", {
                attrs: {
                  width: "240px",
                  ifIconShow: false,
                  placeholderText: "请输入5个字符搜索",
                  requestUrl: _vm.requestUrl,
                  ifAuthority: false,
                  disabled: ""
                },
                on: {
                  unitNameDel: _vm.unitNameDel,
                  change: _vm.unitNameSelect,
                  loading: data => _vm.$emit("loading", data)
                },
                model: {
                  value: _vm.mainForm.organName,
                  callback: function($$v) {
                    _vm.$set(_vm.mainForm, "organName", $$v)
                  },
                  expression: "mainForm.organName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "110px",
                label: "社会信用代码：",
                prop: "creditCode"
              }
            },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                staticStyle: { width: "240px !important" },
                attrs: {
                  placeholder: "请输入社会信用代码",
                  clearable: "",
                  disabled: _vm.creditCodeDisabled,
                  disabled: ""
                },
                model: {
                  value: _vm.mainForm.creditCode,
                  callback: function($$v) {
                    _vm.$set(_vm.mainForm, "creditCode", $$v)
                  },
                  expression: "mainForm.creditCode"
                }
              })
            ],
            1
          ),
          _c("div"),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "经营地区：",
                    prop: "zoneCode"
                  }
                },
                [
                  _c("zwx-select-area", {
                    ref: "zoneCodeArea",
                    staticClass: "select-component",
                    attrs: {
                      placeholder: "请选择",
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: true
                    },
                    on: { change: _vm.nativePlaceChange }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "经营地址：",
                    prop: "workAddr"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "240px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "400",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.workAddr,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "workAddr", $$v)
                      },
                      expression: "mainForm.workAddr"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "法人：",
                    prop: "legalPerson"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "240px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "20",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.legalPerson,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "legalPerson", $$v)
                      },
                      expression: "mainForm.legalPerson"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "法人电话：",
                    prop: "telephone"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "240px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "400",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.telephone,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "telephone", $$v)
                      },
                      expression: "mainForm.telephone"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "联系人：",
                    prop: "linkMan"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "240px !important" },
                    attrs: {
                      maxlength: "20",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.linkMan,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "linkMan", $$v)
                      },
                      expression: "mainForm.linkMan"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "联系人电话：",
                    prop: "linkMobile"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "240px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "400",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.linkMobile,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "linkMobile", $$v)
                      },
                      expression: "mainForm.linkMobile"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "电子邮箱：",
                    prop: "email"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "240px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.mainForm.email,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "email", $$v)
                      },
                      expression: "mainForm.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "是否启用：",
                    prop: "ifEnable"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      model: {
                        value: _vm.mainForm.ifEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.mainForm, "ifEnable", $$v)
                        },
                        expression: "mainForm.ifEnable"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: true } },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: false } },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("attachmen-upload", {
                attrs: {
                  labelwidth: "110px",
                  folder: "repository/technical",
                  label: "备案信息：",
                  accept: ".png,.PNG,.jpg,.JPG,.JPEG,.jpeg,.gif,.pdf,.PDF",
                  limit: 5,
                  fileProp: "annexList1"
                },
                model: {
                  value: _vm.mainForm.annexList1,
                  callback: function($$v) {
                    _vm.$set(_vm.mainForm, "annexList1", $$v)
                  },
                  expression: "mainForm.annexList1"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "营业执照：",
                    prop: "licensePath"
                  }
                },
                [
                  _c(
                    "file-upload",
                    {
                      ref: "coverPictureRef2",
                      attrs: {
                        accept: ".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF",
                        size: 5 * 1024 * 1024,
                        sizeInfo: "5M"
                      },
                      on: {
                        showFileList: _vm.picFileShowFileList2,
                        success: _vm.picFileUploadSuccess2
                      }
                    },
                    [
                      _vm.$zwxBase.verifyIsBlank(_vm.mainForm.annexList2)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-icontext-26",
                              attrs: { icon: "el-icon-upload2" }
                            },
                            [_vm._v("添加附件")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.$zwxBase.verifyIsNotBlank(_vm.mainForm.annexList2)
                    ? _c(
                        "div",
                        { staticClass: "show-file" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              on: {
                                click: function($event) {
                                  return _vm.openFilePreview1(
                                    _vm.mainForm.annexList2,
                                    0
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.mainForm.licenseName))]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.deletionPicFile2(0)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "员工证明：",
                    prop: "employerPath"
                  }
                },
                [
                  _c(
                    "file-upload",
                    {
                      ref: "coverPictureRef3",
                      attrs: {
                        accept: ".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF",
                        size: 5 * 1024 * 1024,
                        sizeInfo: "5M"
                      },
                      on: {
                        showFileList: _vm.picFileShowFileList3,
                        success: _vm.picFileUploadSuccess3
                      }
                    },
                    [
                      _vm.$zwxBase.verifyIsBlank(_vm.mainForm.annexList3)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-icontext-26",
                              attrs: { icon: "el-icon-upload2" }
                            },
                            [_vm._v("添加附件")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.$zwxBase.verifyIsNotBlank(_vm.mainForm.annexList3)
                    ? _c(
                        "div",
                        { staticClass: "show-file" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              on: {
                                click: function($event) {
                                  return _vm.openFilePreview1(
                                    _vm.mainForm.annexList3,
                                    0
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.mainForm.employerName))]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.deletionPicFile3(0)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }